import * as React from "react";

import {Helmet} from 'react-helmet';


import '../styles.scss';


const IndexRoute = () => {

  return (
    <>
      <Helmet>
        <title>
          Jenni & Dávid esküvő
        </title>
      </Helmet>
      <main className="loading">
        💍
      </main>
    </>
  );
};
export default IndexRoute;
